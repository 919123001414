<template>
  <div>

     <div v-for="(item,index) in dataList" :key="item.title" class="item posre" @click="indexClick(index)">
       <!-- 搜索 -->
       <div class="headerSearch" v-if="item.type === 'search'">
         <template v-if="item.data.list">
           <img  :src="(item.data.list[0].img.indexOf('http') > -1 ? item.data.list[0].img : baseImgUrl+item.data.list[0].img)" />
         </template>
         <span class="plaText">请输入商品名称</span>
       </div>
       
       <div v-if="item.type === 'notice'" class="noticeBox">
         <div class="nIconBox">
           <img src="../../../assets/images/gonggaoIcon.png" class="ggIcon" />
           
         </div>
         <!--公告栏-->
         <div class="nTextBox">
            <el-carousel height="24px" arrow="never" indicator-position="none">
              <el-carousel-item v-for="textItem in item.data" :key="textItem.text">
                <div class="textInner">
                  <span class="text">{{ textItem.text }}</span>
                  <img class="jtIcon" src="../../../assets/images/jtArrow.png" />
                </div>
              </el-carousel-item>
            </el-carousel>
         </div>
       </div>

       <div class="w100p borderbox pl10 pr10 d-flex justify-content-between flex-wrap-y posre "  v-if="item.type === 'tab'">
         <div class="d-flex flex-column align-items-center bgfff mb10 br10 "  v-for="(item,k) in item.data.list" :class="k == 3 ? '' : k == 7 ? '' : 'mr24' "  :key="k">
           <el-image :src="ossUrl+item.img" class="h45 w45 mb10"/>
           <div class="bgfff fs7 pb5 brbottom10 cA2">{{item.title}}</div>
         </div>
       </div>

       <el-carousel v-if="item.type === 'banner'"  height="100px">
         <el-carousel-item v-for="(item,itemIndex) in item.data.list" :key="itemIndex" class="w100p">
           <el-image  :src="ossUrl+item.img" />
         </el-carousel-item>
       </el-carousel>


       <!-- 今日推荐 -->
       <div class=" pl10 pr10 posre mt20" v-if="item.type === 'diy'">
         <div class="flex-c-c posre fs9 fwbold lineheight1 mb15">
           {{item.data.title}}
           <img src="@/static/departmentStore/title_bg1.png" class="w100 h10 posab choicenessBgImg"/>
         </div>
         <div class="d-flex justify-content-between">
           <div class="d-flex todayRecommend" v-for="(item,k) in item.data.list" :key="k">
             <el-image :src="ossUrl+item.img" class="w100p h100p"/>
             <div class="todayRecommendTitle">
               {{item.title}}
             </div>
           </div>
         </div>
       </div>

       <!--商品组-->
       <div class="mr10 ml10 mt10 pb20 posre" v-if="item.type === 'group'">
         <div v-if="item.data.style===0">
             <div class="flex-c-c posre fs9 fwbold lineheight1 mb15">
                 {{item.title}}
                 <img src="@/static/departmentStore/title_bg3.png" class="w100 h10 posab choicenessBgImg">
             </div>
             <div class="d-flex flex justify-content-between flex-wrap-y" v-if="item.data && item.data.good">
                 <div class="choicenessBoxA" :class="{'mr18':k%2 != 1}" v-for="(goodItem,k) in item.data.good" :key="k">
                     <div style="position: relative">
                         <el-image :src="goodItem.imgList ? ossUrl+goodItem.imgList.split(',')[0] : ''" class="w130 h130 mb10"/>
                         <el-image :src="ossUrl+killIcon" class="img-label" v-if="goodItem.isKill"/>
                         <el-image :src="ossUrl+assembleIcon" class="img-label" v-if="goodItem.isAssem"/>
                     </div>

                     <div class="textc pl9 pr9">
                         <p class="corange fs8">￥{{goodItem.price | formatAmount}}</p>
                         <p class="cA1 fs7">{{goodItem.goodsName}}</p>
                     </div>
                 </div>
             </div>
         </div>
       </div>

       <div class="posab control"  :class="curIndex === index ? 'fade_in' : 'fade_out' ">
          <i class="el-icon-caret-top cfff" @click.stop="templateUp(index)"></i>
          <i class="el-icon-caret-bottom cfff" @click.stop="templateDown(index)"></i>
          <i class="el-icon-delete cfff" @click.stop="templateDelete(index)"></i>
       </div>
     </div>
  </div>
</template>
<script>
import {get} from "@/utils/request";
import good from "@/mixins/good";
import {
  baseImgUrl
} from '@/utils/constant'
import comm from "@/mixins/comm";

export  default {
  data(){
    return{
      ossUrl:this.$store.state.ossUrl,
      seckillList: null,
        groupList: null,
        goodGroup: {},
        killIcon: 'yimai_photos/crmRevision/img_label_2.png',
        assembleIcon: 'yimai_photos/crmRevision/img_label_3.png',
        baseImgUrl: 'https://mzmpic.oss-cn-beijing.aliyuncs.com/',
    }
  },
  mixins:[good,comm],
  props:{
    dataList: {
      type: Array
    },
    curIndex:{
      type: Number,
      default: 0
    }
  },
  mounted(){
    console.log('初始化',this.dataList)
      // this.init();
  },
  methods:{
    init(){
      console.log('zujin',this.dataList)
      this.dataList.forEach((item,index)=>{
       if(item.type==='group'){
           if(item.data.type===1){
               //秒杀
               this.initKillList(index)
           }else if(item.data.type===2){
               //拼团
               this.initGroupList(index)
           }else { // 商品组
               this.initGoodGroup(index,item.data.id)
           }
            //this.initGroupList()
        }
      })
    },
    indexClick(index){
      this.$emit('indexChange',Number(index))
    },
    changeInitGoodData(index, id) {
      let oldData = this.dataList[index]
      if(id == 'skill') {
        this.initKillList(index)
      } else if (id == 'group') {
        this.initGroupList(index)
      } else {
        this.initGoodGroup(index,id)
      }
    },
    initKillList(index){
      let oldData = this.dataList[index]
        if(this.seckillList!==null){
            oldData.data.good = this.seckillList
            this.$emit('changeShopData', index, 'data', oldData.data)
            // this.$set(this.goodGroup,index,this.seckillList)
        }else {
            get('/goods/goodList',{isKill: true}).then(res=>{
                this.seckillList=res.list
                oldData.data.good = this.seckillList
                this.$emit('changeShopData', index, 'data', oldData.data)
                // this.$set(this.goodGroup,index,this.seckillList)
            })
        }
    },
    initGroupList(index){
      let oldData = this.dataList[index]
        if(this.groupList!==null){
            this.$emit('changeShopData', index, 'data', oldData.data)
            oldData.data.good = this.groupList
            // this.$set(this.goodGroup,index,this.groupList)
        }else {
            get('/goods/goodList',{isAssem: true}).then(res=>{
                this.groupList=res.list
                oldData.data.good = this.groupList
                this.$emit('changeShopData', index, 'data', oldData.data)
                // this.$set(this.goodGroup,index,this.groupList)
            })
        }

    },
    initGoodGroup(index,id){
        let params = {
          pageNum:1,
          pageSize: 999,
        }
        if(id) {
          params.groupId = id
        }
        get('/goods/goodGroupInfo', params).then(res => {
             let oldData = this.dataList[index]
             oldData.data.good = res.list
             this.$emit('changeShopData', index, 'data', oldData.data)
            // this.$set(this.goodGroup,index,res.list)
            //this.goodGroup[index]=res.list
            //console.log('goodGroup',this.goodGroup)
        })
     // }

    },
    // 上移
    templateUp(){
      let arr = this.dataList,
          index = this.curIndex
      if(index !== 0){
        this.swapArray(arr,this.curIndex,--this.curIndex)
      }else{
        this.$message.error('已经处于置顶，无法上移')
      }
    },
    // 下移
    templateDown(){
      let arr = this.dataList,
          length = this.dataList.length,
          index = this.curIndex
      if(index+1 != length){
        this.swapArray(arr,this.curIndex,++this.curIndex)
      }else{
        this.$message.error('已经触底，无法下移')
      }
    },
    // 删除
    templateDelete(index){
      this.dataList.splice(index,1)
    },
    swapArray(arr, index1, index2) {
      arr[index1] = arr.splice(index2, 1, arr[index1])[0];
      return arr;
    },
  }
}
</script>
<style lang="scss" scoped>
.phoneClass{
  padding:50px 30px 0px 30px;
  background-image: url('../../../assets/images/iphonex.png');
  width:400px;
  height:800px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}
.imgScroll::-webkit-scrollbar { width: 0 !important }
.group_title{
  background-image:url('../../../static/ecommerce/bg1.png');
  background-repeat:no-repeat;
  background-size: 100%;
}
.group_bgcolor{
  background: linear-gradient(0deg, rgba(253, 78, 86, 0.3), #FD4E56);
}
.purchase{
  width: 103px;
  height: 32px;
  background: #FFA133;
  color: #fff;
  border-radius: 16px 0px 0px 16px;
  right: -10px;
  bottom: 15px;
  display: flex;
  font-weight: bold;
  align-items: center;
  padding-left: 11px;
  font-size: 16px;
}
.choicenessBgImg{
  bottom: 0px;
}
.fade_in{
  opacity: 1;
  z-index: 99;
  transition:all 0.2s;
}
.fade_out{
  opacity: 0;
  display: none !important;
  transition:all 0.2s;
}
.control{
  right: 0px;
  top: 0px;
  width: 25px;
  height: 150px;
  background: #51CDCB;
  z-index: 99;
  display: flex;
  justify-content:space-around;
  flex-direction: column;
  font-size: 18px;
}
/deep/.addTabIcon .el-upload--text{
  width: 210px;
  height: 100px;
  line-height: 100px;
  margin-bottom: 10px;
}
/deep/ .addTabIcon .el-upload-list__item {
  width: 210px;
  height: 100px;
  line-height: 100px;
}
.avatar{
  width: 100%;
  height: 100%;
}
.todayRecommend{
  width: 152px;
  height: 91px;
  border-radius: 10px;
  position: relative;
}
.todayRecommendTitle{
  position: absolute;
  bottom: 0;
  width: 100%;
  left: 0;
  height: 27px;
  background: linear-gradient(0deg, #000000, rgba(0, 0, 0, 0));
  color: #fff;
  font-size: 13px;
  line-height: 27px;
  border-bottom-left-radius:10px;
  border-bottom-right-radius:10px;
}
.choicenessBox{
  padding:9px;
  width: 100%;
  background: #FFFFFF;
  box-shadow: 0px 2px 7px 0px rgba(56, 56, 56, 0.1);
  border-radius: 9px;
  display: flex;
}
.choicenessBoxA{
  padding:9px;
  width: 150px;
  margin-bottom: 10px;
  background: #FFFFFF;
  box-shadow: 0px 2px 7px 0px rgba(56, 56, 56, 0.1);
  border-radius: 9px;
}
.Immediatelykill{
  width: 45px;
  height: 45px;
  background: rgba(253, 78, 86,0.08);
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #FD4E56;
  font-size: 13px;
  font-weight: bold;
  line-height: 1.4;
}

.cen_btn{
  display: block;
  margin: 0 auto;
}

.noticeBox{
  background: #F4F7FF;
  border-radius: 45px;
  // display: flex;
  font-size: 14px;
  font-family: "微软黑体";
  font-weight: 400;
  padding: 4px 20px;
  margin-bottom: 10px;
  position: relative;
  height: 30px;
}
.ggIcon{
  width: 20px;
  height: 20px;
}
.nIconBox{
  position: absolute;
  left: 20px;
  top: 4px;
}
.nTextBox{
}
.textInner{
  text-align: left;
  padding-left: 40px;

}
.textInner .text{
  overflow:hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  -o-text-overflow:ellipsis;
  display: inline-block;
  width: 95%;
  vertical-align: middle;
}
.jtIcon{
  vertical-align: middle;
  width: 5px;
  height: 10px;
}

.headerSearch{
  height: 40px;
  background: #F4F7FF;
  border-radius: 20px;
  text-align: left;
  padding: 6px 30px;
  margin-bottom: 10px;
}
.headerSearch img{
  width: 15px;
  margin-right: 10px;
  vertical-align: middle;
}
.plaText{
  font-size: 14px;
  font-family: "微软黑体";
  font-weight: 400;
  color: #BEC6CC;
  vertical-align: middle;
}

.img-label{
  position: absolute;
  width: 60px;
  left: 0;
  bottom: 10px;
}

</style>

