<template>
  <div class="w100p" style="background: white">
    <div class="flex-a-b-c h70 pr20 mb20">
      <div class="d-flex jsend">
        <el-button size="small"
                   type="primary" @click="del_template">
          默认皮肤
        </el-button>
        <el-button size="small"
                   type="primary"
                   @click="save_template">
          保存
        </el-button>
      </div>
    </div>
    <el-row>
        <el-col :span="4">
          <div style="color: red;width: 100%;height: 100px"/>
        </el-col>
        <el-col :span="10">
            <div class="flex-c-c pt30 pb30">
                <div class="phoneClass borderboxd-flex flex-column">
                    <div class="d-flex oyauto imgScroll" style="height:700px;width:100%;">
                        <div class="felx1 text-align">
                            <p class="fs10 " style="line-height: 40px;">商城</p>
                            <!-- 商城模板 -->
                            <div class="d-flex flex-column">
                                <mobile ref="mobile" :data-list="shopData" :cur-index="curIndex" @indexChange="indexChange" @changeShopData="changeShopData"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </el-col>
        <el-col :span="8">
           <el-card style="width: 90%;margin: 0 auto">
              <div v-if="curData.type === 'search'">
                  <pic-manager  :item="curData.data.list[0]" :size-type="1" :hiddenToUrl="true" @urlChange="urlChange" @valueChange="valueChange(curData.data.list[curImgIndex],arguments)">
                  </pic-manager>
              </div>
               <div v-if="curData.type === 'notice'">
                   <el-form label-width="100px">
                       <div v-for="(subItem,index) in curData.data" :key="index">
                           <el-form-item label="公告内容" >
                              <el-input type="textarea" :rows="4" placeholder="请输入公告栏内容" v-model="subItem.text" show-word-limit></el-input>
                              <el-button style="margin-top: 5px;" @click="delNotice(index)" v-if="index > 0">删除</el-button>
                           </el-form-item>
                       </div>
                       <el-button @click="addNotice">添加一项</el-button>
                   </el-form>
               </div>
               <div v-if="curData.type === 'tab'">
                   <div class="d-flex flex-wrap-y">
                       <el-row>
                           <el-col :span="6" v-for="(item,index) in curData.data.list" :key="index"  style="margin: 10px 0">
                               <el-image :src="ossUrl+item.img" v-if="item.img" class="w70 h70" style="display: block;margin: 0 auto" @click="imgItemClick(index)" />
                           </el-col>
                       </el-row>
                   </div>
                   <pic-manager :item="curData.data.list[curImgIndex]" :show-text="true" @valueChange="valueChange(curData.data.list[curImgIndex],arguments)" @urlChange="urlChange">
<!--                       <template v-slot:top>-->
<!--                               <el-form-item label="标题设置">-->
<!--                                   <el-input :placeholder="curData.data.list[curImgIndex].title" v-model="curData.data.list[curImgIndex].title" maxlength="4" show-word-limit class="w200"></el-input>-->
<!--                               </el-form-item>-->
<!--                       </template>-->
                   </pic-manager>
               </div>
               <div v-if="curData.type === 'banner'||curData.type==='diy'">
                   <div v-for="(item, bIndex) in curData.data.list" :key="item.img" class="pt10">
                       <div @click="imgItemClick(bIndex)">
                        <pic-manager :item='item' :show-text="curData.type==='diy'" @valueChange="valueChange(item,arguments)"  @urlChange="urlChange" :size-type="1">
                        </pic-manager>
                       </div>
                       <template v-if="curData.type === 'banner'">
                           <el-button @click="valueAddImgChange(curData.data)" >添加一项</el-button>
                           <el-button @click="valueDelImgChange(bIndex)" v-if="curData.data.list.length > 0">删除</el-button>
                       </template>
                   </div>
               </div>
               <div v-if="curData.type==='group'">
                   <el-form label-width="80px">
                       <el-form-item label="标题">
                         
                           <el-input v-model="curData.title" placeholder="请输入标题"></el-input>
                       </el-form-item>
                       <url-setting type="1" @urlChange="urlChange" :curType="curType"/>
                   </el-form>
               </div>
           </el-card>
        </el-col>
    </el-row>
  </div>
</template>

<script>
import {
  saveShopModelConfig,
  queryShopModelConfig,
  updateShopModelConfig,
  saveShopConfigHome,
  showShopConfigHome,
} from "@/api/wechat";
import tab from '@/pages/miniProgram/template/ecommerce/tab'
import  {defaultShopData} from "@/utils/shop";
import PicManager from "@/pages/shop/component/PicManager";
import Mobile from "@/pages/shop/component/Mobile";
import UrlSetting from "@/pages/shop/component/UrlSetting";



export default {
  components:{
      UrlSetting,
    Mobile,
    PicManager
  },
  data() {
    let token = localStorage.getItem("token")
    return {
      shopId: null,
      shopData: [],
      curImgIndex: 0,
      uploadUrl:this.$store.state.uploadingUrl,
      ossUrl:this.$store.state.ossUrl,
      upParams: {
        token: token
      },
      isUpdate:false,
      currentType:'',
      requestWay:'save',
      currentIndex:0,
      currentSelect:'',
      curData: {},
      curIndex: 0,
      curType: [], // 商品组跳转连接
    };
  },
  mounted(){
    let params = {
      entId: JSON.parse(localStorage.getItem('info')).companyId
    }
    showShopConfigHome(params).then(res => {
      if(res.data && res.data.content) {
        this.shopData = JSON.parse(res.data.content)
        this.shopId = res.data.id
      }else {
        this.shopData = defaultShopData
        this.shopId = null
      }
    })
    this.$nextTick(()=>{
      this.$refs.mobile.init()
    })

  },

  methods:{
      del_template() {
        let params ={
          content: [],
        }
        if(this.shopId)  {
          params.id = this.shopId
        }
        saveShopConfigHome(params).then(res => {
          than.$message.success('设置成功')
        })
      },
      // 商品组数据修改
      changeShopData(index,key,data) {
        this.shopData[index][key] = data
      },
      addNotice(){
          this.curData.data.push({text: ''})
      },
      delNotice(index) {
         this.curData.data.splice(index, 1)
      },
    indexChange(index){
       this.curIndex=index
       this.curData=this.shopData[index]
       this.curType = this.shopData[index].set
       console.log('shop',index,this.curData)
    },
    addLine(){
      this.curData.list.push({})
    },
    imgItemClick(index){
      this.curImgIndex=index;
    },
    valueChange(obj,params){
      if(!obj){
        obj=this.curData
      }
      if(obj){
        obj[params[0]]=params[1]
      }
      this.shopData[this.curData][params[0]] = params[1]
    },
    valueChange2(data) {
        this.curData.data[data[0]] = data[1]
    },
    valueAddImgChange(obj) {
      if(!obj){
        obj=this.curData
      }
      if(obj.list) {
        obj.list.push(
          {
              img:'/yimai_photos/departmentStore/banner1.png',
              url:[],
          }
        )
      }
    },
    valueDelImgChange(index) {
      var obj=this.curData.data
      if(obj.list) {
        obj.list.splice(index, 1)
      }
    },
    // 精品推荐，修改商品组
    urlChange(arr, innerIndex) {
      console.log('this.curData', this.curData)
      this.curType = arr
      console.log('curType', arr)
      if(this.curData.type === 'group') {
        // if(this.curData.data.type == 3) {
          this.curData.data.id = arr[1]
          this.shopData[this.curIndex].data.id = arr[1]
          this.$refs.mobile.changeInitGoodData(this.curIndex, arr[1])
          this.shopData[this.curIndex].set = arr
          // this.$refs.mobile.initGoodGroup(this.curIndex,this.curData.data.id)
        // }
        // else if (this.curData.data.type == 1) {
          // this.$refs.mobile.initKillList(this.curIndex)
        // }else if(this.curData.data.type == 2) {
          // this.$refs.mobile.initGroupList(this.curIndex)
        // }
         this.shopData[this.curIndex].set = arr
      }else {
        if(this.curData.type == 'banner') {
           let oData= this.shopData[this.curIndex].data
           oData.list[this.curImgIndex].set = arr
           this.shopData[this.curIndex]['data'] = oData
          //  this.$set(this.shopData[this.curImgIndex], 'data', oData)
        }else if(this.curData.type == 'diy') {
          let oData= this.shopData[this.curIndex].data
          oData.list[this.curImgIndex].set = arr
          this.shopData[this.curIndex]['data'] = oData
          // this.$set(this.shopData[this.curImgIndex], 'data', oData)
        } else if(this.curData.type == 'tab') { 
          let oData= this.shopData[this.curIndex].data
          oData.list[this.curImgIndex].set = arr
          this.shopData[this.curIndex]['data'] = oData
          // this.$set(this.shopData[this.curImgIndex], 'data', oData)
        } else if (this.curData.type == 'search') {
          let oData= this.shopData[this.curIndex].data
          oData.list[this.curImgIndex].set = arr
          this.shopData[this.curIndex]['data'] = oData
        }

        // 处理其他数据，list 加 set 存储选中的url配置
        // if(this.curData.type === 'tab') {
        //   let oldData= this.shopData[this.curIndex].data
        //   oldData[this.curImgIndex].set = arr
        // }
      }
    },
    queryShopConfig(){
      let data = {
        modelEnum:'daily_necessities',
      }
      queryShopModelConfig(data)
          .then(res=>{
            console.log(res)
            if(res.data.modelId){
              this.requestWay = 'up'
              this.ecommerceList = JSON.parse(res.data.shopDetails[0].content)
            }
          })
    },
    //保存模板
    save_template(){
      let than = this
      let params ={
        content: JSON.stringify(than.shopData)
      }
      if(this.shopId) {
        params.id = this.shopId
      }
      saveShopConfigHome(params).then(res => {
        than.$message.success("保存成功")
      })
      // let arr = JSON.parse(JSON.stringify(than.shopData))
      // let p = new Promise((resolve,reject) => {
      //   arr.forEach((item,k)=>{
      //     if(item.list){
      //       item.list.forEach((v,k)=>{
      //         if(item.type == 'seckill'){
      //           if(!v.goodsId){
      //             reject("秒杀模块未设置商品")
      //           }
      //         }
      //         if(item.type == 'group'){
      //           if(!v.goodsId){
      //             reject("拼团模块未设置商品")
      //           }
      //         }
      //         if(item.type == 'boutique'){
      //           if(!v.goodsId){
      //             reject("精选模块未设置商品")
      //           }
      //         }

      //       })
      //     }
      //   })
      //   resolve('succ')
      // })
      // p.then(res => {
      //   console.log(res,'22222');
      //   let data = {
      //     modelEnum:'daily_necessities',
      //     shopDetails:[{
      //       content:JSON.stringify(arr)
      //     }]
      //   }
      //   if(than.requestWay == 'save'){
      //     saveShopModelConfig(data)
      //         .then(res=>{
      //           if(res.code == 200){
      //             than.$message.success("保存成功")
      //             setTimeout(()=>{
      //               than.queryShopConfig()
      //               than.$router.push('mallSetting')
      //             },500)
      //           }
      //         })
      //         .catch(err => {
      //           if(err.code == 201){
      //             this.$message.error(err.message)
      //           }
      //           console.log(err,'wwwww');
      //         })
      //   }else{
      //     updateShopModelConfig(data)
      //         .then(res=>{
      //           if(res.code == 200){
      //             than.$message.success("保存成功")

      //             setTimeout(()=>{
      //               than.queryShopConfig()
      //               than.$router.push('mallSetting')
      //             },500)
      //           }
      //         })
      //         .catch(err => {
      //           if(err.code == 201){
      //             this.$message.error(err.message)
      //           }
      //           console.log(err,'wwwww');
      //         })
      //   }
      // })
      //     .catch(err => {
      //       this.$message.error(err)
      //       console.log(err,'wwwww');
      //     })
    },
  }
};
</script>

<style lang="scss" scoped>
.phoneClass{
  padding:50px 30px 0px 30px;
  background-image: url('../../assets/images/iphonex.png');
  width:400px;
  height:800px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}
.imgScroll::-webkit-scrollbar { width: 0 !important }
.group_title{
  background-image:url('../../static/ecommerce/bg1.png');
  background-repeat:no-repeat;
  background-size: 100%;
}
.group_bgcolor{
  background: linear-gradient(0deg, rgba(253, 78, 86, 0.3), #FD4E56);
}
.purchase{
  width: 103px;
  height: 32px;
  background: #FFA133;
  color: #fff;
  border-radius: 16px 0px 0px 16px;
  right: -10px;
  bottom: 15px;
  display: flex;
  font-weight: bold;
  align-items: center;
  padding-left: 11px;
  font-size: 16px;
}
.choicenessBgImg{
  bottom: 0px;
}
.fade_in{
  opacity: 1;
  z-index: 99;
  transition:all 0.2s;
}
.fade_out{
  opacity: 0;
  display: none !important;
  transition:all 0.2s;
}
.control{
  right: 0px;
  top: 0px;
  width: 25px;
  height: 150px;
  background: #51CDCB;
  z-index: 99;
  display: flex;
  justify-content:space-around;
  flex-direction: column;
  font-size: 18px;
}
/deep/.addTabIcon .el-upload--text{
  width: 210px;
  height: 100px;
  line-height: 100px;
  margin-bottom: 10px;
}
/deep/ .addTabIcon .el-upload-list__item {
  width: 210px;
  height: 100px;
  line-height: 100px;
}
.avatar{
  width: 100%;
  height: 100%;
}
.todayRecommend{
  width: 152px;
  height: 91px;
  border-radius: 10px;
  position: relative;
}
.todayRecommendTitle{
  position: absolute;
  bottom: 0;
  width: 100%;
  left: 0;
  height: 27px;
  background: linear-gradient(0deg, #000000, rgba(0, 0, 0, 0));
  color: #fff;
  font-size: 13px;
  line-height: 27px;
  border-bottom-left-radius:10px;
  border-bottom-right-radius:10px;
}
.choicenessBox{
  padding:9px;
  width: 100%;
  background: #FFFFFF;
  box-shadow: 0px 2px 7px 0px rgba(56, 56, 56, 0.1);
  border-radius: 9px;
  display: flex;
}
.choicenessBoxA{
  padding:9px;
  width: 150px;
  margin-bottom: 10px;
  background: #FFFFFF;
  box-shadow: 0px 2px 7px 0px rgba(56, 56, 56, 0.1);
  border-radius: 9px;
}
.Immediatelykill{
  width: 45px;
  height: 45px;
  background: rgba(253, 78, 86,0.08);
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #FD4E56;
  font-size: 13px;
  font-weight: bold;
  line-height: 1.4;
}

.cen_btn{
  display: block;
  margin: 0 auto;
}

.btn_group{
   margin:  0 auto;
   overflow: hidden;
   padding: 10px;
   border: 1px solid #f1f1f1;
   width: 94%;
}

.btn_item{
  width: 25%;
  float: left;
  text-align: center;
  border: 1px solid #f1f1f1;
  line-height: 30px;
  cursor:pointer;
}
.pt10{
  padding-top: 15px;
}
.jsend{
  justify-content: flex-end;
  width: 100%;
}
</style>
